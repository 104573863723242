<template>
  <div class="json-analysis">
    <textarea class="json-input" v-model="inputJson" @scroll="inputScroll($event)"></textarea>
    <JsonViewer
      @scroll="showScroll($event)"
      class="json-view"
      :value="jsonData"
      expand-depth="10"
      :copyable="{ copyText: '复制', copiedText: '已复制', timeout: 2000 }"
      theme="light"
    />
  </div>
</template>

<script>
import { JsonViewer } from 'vue3-json-viewer'
import 'vue3-json-viewer/dist/index.css'
export default {
  name: 'JsonAnalysis',
  components: {
    JsonViewer
  },
  data() {
    return {
      inputJson: '',
      jsonData: {},
      theme: ''
    }
  },
  methods: {
    inputScroll(event) {
      const parent = event.currentTarget.parentElement
      if (parent) {
        parent.lastElementChild.scrollTop = parent.firstElementChild.scrollTop
      }
    },
    showScroll(event) {
      const parent = event.currentTarget.parentElement
      if (parent) {
        parent.firstElementChild.scrollTop = parent.lastElementChild.scrollTop
      }
    },
    formatJson(value) {
      try {
        this.jsonData = JSON.parse(value)
        this.$emit('jsonDynamic', value, '', true)
      } catch (error) {
        console.log(value, error)
        const errorMsg = error.message
        const index = Number(errorMsg.substring(errorMsg.lastIndexOf(' ')))
        this.jsonData = value.substring(0, index + 1)
        this.$emit('jsonDynamic', value, value.substring(index - 20, index + 20), false)
      }
    }
  },
  watch: {
    inputJson: function (value) {
      console.log(value === '')
      this.formatJson(value === '' ? '{}' : value)
    }
  },
  mounted() {
    const json = this.$parent.$parent.getJsonData()
    if (json != null) {
      this.inputJson = json
    }
  }
}
</script>

<style scoped>
.json-analysis {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: space-around;
}

.json-input {
  width: 50%;
  height: 100%;
  resize: none;
  outline: none;
  padding: 10px 20px;
  color: rgb(87, 87, 87);
  vertical-align: top;
  font-size: 15px;
  letter-spacing: 1px;
  box-sizing: border-box;
  border: 1px solid #eee;
}

.json-view {
  width: 50%;
  height: 100%;
  font-size: 15px;
  overflow: auto;
  border: 1px solid #eee;
}
/*允许选中复制*/
.json-view >>> * {
  -webkit-user-select: text;
  -ms-user-select: text;
  -moz-user-select: text;
  user-select: text;
}

.json-view >>> .jv-code {
  max-height: none;
  font-weight: bold;
  white-space: nowrap;
  padding: 10px 50px;
  font-family: menlo, monospace, Tahoma, '微软雅黑', '幼圆';
}

.json-view >>> .jv-key {
  color: #92278f;
}

.json-view >>> .jv-more {
  display: none;
}
</style>
